<template>
  <div class="details-container">
    <div class="subheader">
      Verify the information below to upgrade your StarTram subscription to a paid account.
    </div>
    <table class="details-table">
      <tr>
        <th>Subscriber ID</th>
        <td>{{ customerData.url_id }}</td>
      </tr>
      <tr>
        <th>Email</th>
        <td>{{ customerData.email }}</td>
      </tr>
      <tr>
        <th>Expiry date</th>
        <td>{{ customerData.expiry }}</td>
      </tr>
    </table>

    <div class="subscription-selection">
      <input type="radio" id="month" value="month" v-model="subscriptionType">
      <label for="month">Monthly ($5USD)</label>
    </div>
    <div class="subscription-selection">
      <input type="radio" id="year" value="year" v-model="subscriptionType">
      <label for="year">Annual ($50USD)</label>
    </div>

    <button class="submit-button" @click="submitSubscription" :disabled="isLoading">
      <span v-if="isLoading">Processing...</span>
      <span v-else>Submit</span>
    </button>
    <div v-if="error" class="error-message">{{ error }}</div>
  </div>
</template>

<script>
import axios from 'axios';
import { inject, ref } from 'vue';

export default {
  setup() {
    const state = inject('state');
    const subscriptionType = ref('');
    const isLoading = ref(false);
    const error = ref('');

    const submitSubscription = async () => {
      isLoading.value = true;
      error.value = '';

      try {
        const response = await axios.post('/cx/sub', {
          url_id: state.urlid,
          term: subscriptionType.value,
        });
        window.location.href = response.data.url;
      } catch (error) {
        console.error('Error creating subscription:', error);
        error.value = 'Failed to create subscription. Please contact support@nativeplanet.io';
      } finally {
        isLoading.value = false;
      }
    }

    return { 
      customerData: state.customerData, 
      urlid: state.urlid, 
      subscriptionType, 
      submitSubscription,
      isLoading,
      error
    };
  },
};
</script>

<style scoped>
.details-container {
  display: flex;
  font-size: 20pt;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  height: 100%;
  background-color: #f0f0f0;
}

.details-table {
  margin-bottom: 20px;
  border-collapse: collapse;
  text-align: left;
}

.details-table th,
.details-table td {
  padding: 10px;
  border: 1px solid #333;
}

.subscription-selection {
  margin-bottom: 20px;
}

.subscription-selection input[type="radio"] {
  margin-right: 5px;
  transform: scale(1.5);
}

.submit-button {
  font-size: 24pt;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #333;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #ddd;
}

.error-message {
  color: #a32323;
  margin-top: 20px;
  text-align: center;
}

.subheader {
    padding-bottom: 30px;
    font-family: 'Nunito', sans-serif;
    font-size: 20pt;
}
</style>
