import { createRouter, createWebHashHistory } from 'vue-router';
import InputForm from './components/InputForm.vue';
import SubDetails from './components/SubDetails.vue';

const routes = [
  {
    path: '/',
    name: 'InputForm',
    component: InputForm
  },
  {
    path: '/details',
    name: 'SubDetails',
    component: SubDetails
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
