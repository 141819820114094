<template>
  <AppHeader />
  <router-view />
</template>

<script>
import { reactive, provide } from 'vue';
import AppHeader from './components/AppHeader.vue';

export default {
  name: 'StartramSubscriber',
  components: {
    AppHeader
  },
  setup() {
    const state = reactive({
      customerData: {},
      urlid: null
    });

    provide('state', state);

    return { state };
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap');

body {
    background-color: #f0f0f0;
    font-family: 'Nunito', sans-serif;
    height: 100%;
}
</style>
