<template>
  <header class="app-header">
    <a href="/"><img src="@/assets/logo.svg" alt="Logo" class="header-logo" /></a>
  </header>
</template>

<script>
export default {
  name: 'AppHeader'
};
</script>

<style scoped>
.app-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
}

.header-logo {
  height: 350px;
}
</style>
